.screen-reader-text{
	@include screen-reader-text();

	h1{
		@include screen-reader-text();
	}
}

body {
	position: relative;
}

#container {
	max-width: 100%;
}

.main,
.sidebar {
	margin-top: $global-margin;
	.single & {
		margin-top: 0;
		@include breakpoint( medium ) {
			margin-top: 5.5rem;
		}
	}
}

.inner-grid {
	margin-left: 1rem;
	margin-right: 1rem;
	z-index: 9;
	position: relative;

	@include breakpoint( large ) {
		margin-left: auto;
		margin-right: auto;
	}
}

#content {
	position: relative;

	.content,
	.excerpt,
	.post-content {
		a {
			@include transition( 200ms );
		}

		h2, h3, h4, h4, h6 {
			a {
				color: $black;
				@include transition( 200ms );
			}
		}
	}

	table {
		td {
			&.left {
				float: none !important;
			}
		}
	}
}

.placeholder {
	height: 100%;
	width: 100%;
}

body.custom-background {
	background-size: cover !important;
	background-position: top center !important;
	@media (max-width: 769px) {
		background-attachment: scroll !important;
	}

	> #container {
		background: linear-gradient(77deg, rgba(0, 0, 0, 0.5) 0px, rgba(0, 0, 0, 0) 800px);
	}
}
