/**
 * General grid structure
 * Used across the site
 */
.header {
	.logo-wrap {
		@include grid-row();
	}

	.top-nav {
		.menu-item {
			@include grid-column( 6 );
		}
	}

	.submenu {
		.menu-widgets {
			@include grid-column( 9, 0 );
			@include grid-column-offset( 3 );
		}
	}
}

.inner-grid {
	@include grid-row();

	.main {
		@include grid-column();

		@include breakpoint( medium ) {
			@include grid-column( 8 );
			@include grid-row-nest;
		}
	}

	.sidebar {
		@include grid-row;

		@include breakpoint( medium ) {
			@include grid-column( 4 );
			@include grid-row-nest;
		}
	}
}

#frontpage {
	@include grid-row;

	.widget-teaser {
		@include breakpoint( large ) {
			@include grid-column( 6, 0 );
		}
	}
}

.before-footer {
	.museum {
		@include grid-row;

		@include breakpoint( medium ) {
			@include grid-column( 4, 0 );
		}
	}
}

.footer {
	.inner-grid {
		@include grid-row();

		.widget-area {
			.widget {
				@include grid-row;

				@include breakpoint( medium ) {
					&:nth-child(1) {
						@include grid-column( 2 );
					}
					&:nth-child(2) {
						@include grid-column( 3 );
					}
					&:nth-child(3) {
						@include grid-column( 3 );
					}
					&:nth-child(4) {
						@include grid-column( 4 );
					}
				}

				@include breakpoint( large ) {
					@include grid-column( 3 );
				}
			}
		}
	}
}
