// This is the layout used for baseline image alignment
.loop-baseline-layout {
	.box a.post-featured, article > header > a {
		display: block;
		position: relative;

		img {
			width: 100%;
			height: auto;
			margin: 0;

			@include breakpoint( medium ) {
				position: absolute;
				bottom: 0px;
				vertical-align: bottom;
			}
		}
	}
}