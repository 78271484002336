.top-bar {
	width: 100%;
	width: 100vw;
	z-index: 100;
	background: $white;
	border-top: rem-calc(40) $gainsboro solid;
	text-align: left;
	padding: $global-margin 0;
	letter-spacing: -0.02em;
	overflow-x: hidden;

	@include breakpoint( medium ) {
		position: static;
		top: auto;
		left: auto;
		padding: 0;
		margin-top: 0;
		height: auto;
		border-top: none;
		overflow: visible;

		&, .menu {
			background-color: $white;
		}
	}

	.top-bar-left,
	.top-bar-right {
		float: none;
	}

	.menu > .menu-item {
		font-family: 'suisse_semibold';
		text-transform: uppercase;
		font-size: 200%;
		padding: 0;
		position: static;
		background: $white;

		@include breakpoint( medium ) {
			font-size: 130%;
			padding-top: 0.5rem;

			&:first-child a {
				padding-left: 0;
			}
		}

		.submenu {
			display: none;
			position: relative;
			height: 0;
			left: 0;
			right: auto;
			max-width: 100%;
			margin: 0;
			width: 100%;
			text-transform: none;
			background: $white;
			overflow: hidden;

			@include breakpoint( medium ) {
				position: absolute;
				z-index: 100;
				display: block;
				left: 0;
				right: 0;
				top: rem-calc(50);
				@include grid-row();
				padding: 0;
			}

			&.js-dropdown-active {
				display: block;
				height: auto;
				padding: 1rem;
				padding-left: 2rem;
				overflow: hidden;

				> li {
					max-width: none !important;
					font-size: 80%;
				}

				@include breakpoint( medium ) {
					height: rem-calc(420);
					padding: 0 1rem 0;
					overflow: visible;
					padding-left: 1rem;

					> li {
						font-size: 100%;

						// 1) First menu item, widgets or not.
						// 2) First menu item after menu widgets.
						&:first-of-type,
						&.menu-widgets + li {
							margin-top: $global-padding;
						}
					}
				}

				@include breakpoint( large ) {
					padding-left: 0;
					padding-right: 0;
				}
			}

			&::before {
				content: '';
				position: absolute;
				top: -1px;
				left: 0;
				width: 100%;
				height: 100%;
				height: calc(100% + 1px);
				z-index: 1;
				@include extend-fullwidth;
				background-color: $white;
			}

			> li {
				position: relative;
				z-index: 1;
				margin-bottom: 0.5rem;
				max-width: rem-calc(270);
			}

			.menu-widgets {
				position: absolute;
				top: 0;
				right: 0;
				z-index: 9;
				max-width: none;
				visibility: hidden;
				.owl_widget {
					position: relative;
					z-index: 999;
				}
			}
		}
	}

	&.dim::after {
		content: '';
		display: block;
		top: rem-calc(90);
		position: absolute;
		width: 100%;
		@include calc( 'height', '100vh - #{rem-calc(90)}' );
		background-color: rgba( 0, 0, 0, 0.75 );

		@include breakpoint( medium down ) {
			display: none !important;
		}
	}

	&::after {
		display: none;
	}
}

.title-bar {
	background: none;
	text-transform: uppercase;
	position: fixed;
	top: 20px;
	right: 0;
	display: inline-block;
	z-index: 101;
	margin-right: 1rem;
	border: rem-calc(1) solid $white;
	background: rgba( 0, 0, 0, 0.2 );
	font-size: 0.9rem;
	.admin-bar & {
		top: 58px;
	}
	.menu-icon {
		margin-right: 50px;
	}

	@include breakpoint( medium ) {
		display: none;
	}

	.title-bar-title {
		font-weight: normal;
		position: absolute;
		top: 0px;
		left: 30px;
	}

	.header.opened & {
		color: $black;
		margin: 0;
		border: none;
		background: none;
	}
}
