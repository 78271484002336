@mixin transition( $duration: 300ms ){
	-webkit-transition: all $duration ease-in-out;
	-moz-transition: all $duration ease-in-out;
	-ms-transition: all $duration ease-in-out;
	-o-transition: all $duration ease-in-out;
	transition: all $duration ease-in-out;
}

@mixin skew( $x: 0deg, $y: 0deg ) {
	-moz-transform: skewX($x) skewY($y);
	-webkit-transform: skewX($x) skewY($y);
	-o-transform: skewX($x) skewY($y);
	-ms-transform: skewX($x) skewY($y);
	transform: skewX($x + deg) skewY($y + deg);
}

@mixin gradient-red(){
	background: rgb(101,3,8); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzY1MDMwOCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4MTA0MGEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  rgba(101,3,8,1) 0%, rgba(129,4,10,1) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(101,3,8,1)), color-stop(100%,rgba(129,4,10,1))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(101,3,8,1) 0%,rgba(129,4,10,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(101,3,8,1) 0%,rgba(129,4,10,1) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(101,3,8,1) 0%,rgba(129,4,10,1) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(101,3,8,1) 0%,rgba(129,4,10,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#650308', endColorstr='#81040a',GradientType=0 ); /* IE6-8 */
}

// Linear gradient cross-browser
@mixin linear-gradient( $direction: top, $from-color: null, $to-color: null ) {

	/* Fallback (could use .jpg/.png alternatively) */
	background-color: $from-color;

	/* SVG fallback for IE 9 (could be data URI, or could use filter) */
	background-image: url(fallback-gradient.svg);

	/* Safari 4, Chrome 1-9, iOS 3.2-4.3, Android 2.1-3.0 */
	background-image:
	-webkit-gradient( linear, top, bottom, from( $from-color ), to( $to-color ) );

	/* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
	background-image:
	-webkit-linear-gradient( top, $from-color, $to-color );

	/* Firefox 3.6 - 15 */
	background-image:
	-moz-linear-gradient( top, $from-color, $to-color );

	/* Opera 11.1 - 12 */
	background-image:
	-o-linear-gradient( top, $from-color, $to-color );

	/* Opera 15+, Chrome 25+, IE 10+, Firefox 16+, Safari 6.1+, iOS 7+, Android 4.4+ */
	background-image:
	linear-gradient( to bottom, $from-color, $to-color );
}

// Cover image - fullwidth, vertical middle
@mixin background-cover() {
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
	z-index: 1;
}

// Border-radius
@mixin border-radius( $radius ) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// Single side border-radius
@mixin border-top-radius( $radius ) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-right-radius( $radius ) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
     border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius( $radius ) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-left-radius( $radius ) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
     border-top-left-radius: $radius;
     background-clip: padding-box;
}

// Box Shadow
@mixin box-shadow( $top, $left, $blur, $spread, $color, $inset: false ) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $spread $color;
    -moz-box-shadow:inset $top $left $blur $spread $color;
    box-shadow:inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}

// Calc
@mixin calc($property, $value) {
	#{$property}: -webkit-calc(#{$value});
	#{$property}: calc(#{$value});
}
