.gallery-wrap,
.owl_widget,
.owl-shortcode {
	margin-bottom: $global-padding;
	@include clearfix;

	.inner-grid {
		background: $charcoal;

		.widget-area &,
		.before-footer & {
			padding: 0;
			// padding-bottom: 1.5 * $global-margin;
		}

		@include breakpoint( medium ) {
			.widget-area &,
			.before-footer & {
				padding: 0;
			}
		}

		@include breakpoint( large ) {
			@include border-top-radius( 4px );
			@include border-bottom-radius( 4px );
			max-width: none !important;

			.widget-area &,
			.before-footer & {
				@include calc( 'width', '#{$global-width} + #{rem-calc(100)}' );
				overflow: hidden;
			}
		}

		.owl-carousel {
			height: rem-calc(320);
			overflow: visible;
			max-width: none;
			width: 100%;

			@include breakpoint( medium ) {
				height: rem-calc(420);
			}

			@include breakpoint( large ) {
				height: rem-calc(570);
			}

			.item {
				.image {
					max-height: 100%;
					padding-top: 40% !important;
					z-index: 1;

					@include breakpoint( medium down ) {
						height: 100% !important;
						width: auto !important;
					}
				}
			}

			.owl-nav {
				> div {
					height: rem-calc(45);
					width: rem-calc(45);
					text-align: center;
					margin: 0;
					margin-top: - 2.25rem;

					@include breakpoint( medium ) {
						height: rem-calc(60);
						width: rem-calc(60);
						padding-top: 0.3rem;
					}

					&::after,
					&::before {
						font-size: 4rem;
					}
				}

				.owl-prev {
					@include icons( '\f104', 'fontawesome', 'before', $white, '0' );

					.menu-widgets & {
						@include icons( '\f104', 'fontawesome', 'before', $white, '0' );
					}
				}
				.owl-next {
					@include icons( '\f105', 'fontawesome', 'after', $white, '0' );

					.menu-widgets & {
						@include icons( '\f105', 'fontawesome', 'after', $white, '0' );
					}
				}
				.owl-prev,
				.owl-next {
					span {
						display: none;
					}
				}
			}

			.owl-dots {
				display: none !important;
				.owl-dot {
					span {
						width: rem-calc(15);
						height: rem-calc(15);
						background: $dark-navy;
						border: rem-calc(2) solid $blue-gray;
						margin: rem-calc(5);

						.menu-widgets & {
							background: $white;
						}
					}

					&.active span {
						background: $blue-gray;
					}
				}
			}
		}
	}
}


.owl_widget {
	.widgettitle {
		display: none;
		color: $white;
		font-family: 'suisse_semibold';
		text-transform: uppercase;
		font-size: 180%;
		margin-bottom: 1rem;

		.widget-area & {
			display: block;
		}
	}

	.inner-grid {
		.owl-carousel {
			height: rem-calc(320);

			.item {
				> a {
					display: block;
				}
				.owl-item-overlay {
					background: transparent;
					z-index: 3;
					top: auto;
					bottom: 0;
					padding: ( 0.5 * $global-padding ) ( 1.75 * $global-padding );
					font-size: 1rem;
					text-align: left;
					color: $white;

					@include breakpoint( medium ) {
						padding: ( 0.5 * $global-padding ) ( 2.25 * $global-padding );
					}

					.owl-item-title {
						font-family: 'harriet_bold';
						font-size: 250%;
						letter-spacing: -0.04em;
						line-height: 120%;
						text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
					}

					.owl-item-content {
						font-family: $body-font-family;
						font-size: 90%;
						line-height: 100%;
						font-weight: bold;
						margin: 1rem 0;

						@include breakpoint( medium ) {
							font-size: 110%;
							line-height: 130%;
						}
					}

					.link {
						a {
							color: $white;
							text-transform: uppercase;
							@include icons( '\f105', 'fontawesome', 'after', '', '0 0 0 8px' );
							font-weight: bold;
							font-size: 70%;
							border: rem-calc(2) solid $white;
							padding: 0.5rem 1.25rem;
							display: inline-block;
						}
					}
				}

				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient( 0deg, rgba( 0, 0, 0, 0.35 ), transparent );
					z-index: 2;
				}
			}
		}
	}
}

.sub-menu .owl_widget {

	.inner-grid {
		background: none;
	}
}

.owl-shortcode .inner-grid .owl-carousel {
	max-width: 100%;
}
