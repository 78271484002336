@mixin clear() {
	&:before, &:after {
		content: "\0020";
		display: block;
		height: 0;
		overflow: hidden;
	}
	&:after {
		clear: both;
	}
}

// Mixins
@mixin background-cover() {
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	z-index: 1;
}

@mixin contain-to-grid(){
	max-width: $row-width;
	margin: 0 auto;
	// padding-left: $grid-column-gutter / 2;
	// padding-right: $grid-column-gutter / 2;
}

@mixin wide-row(){
	max-width: 100%; //rem-calc(1600);
}

// @TODO Get this to work
/* @mixin set-gutter( $gutter: 0px, $grid-column-gutter: $grid-column-gutter ) {
	$grid-column-gutter-tmp: $grid-column-gutter;
	$grid-column-gutter: $gutter;
}

@mixin reset-gutter() {
	$grid-column-gutter: $grid-column-gutter-tmp;
} */

@mixin css-image($height: 150px) {
	height: $height;
	width: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin vertical-align {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

// Scalable containers
@mixin max-width-scale() {
	width: 100%;

	@include breakpoint( medium ) {
		width: nth($breakpoints, 1);
	}
	@include breakpoint( large ) {
		width: nth($breakpoints, 2);
	}
	@include breakpoint( xlarge ) {
		width: nth($breakpoints, 3);
	}
	@include breakpoint( xxlarge ) {
		width: 100%;
	}
}

@mixin outer-container($local-max-width: $global-width) {
	@include clearfix;
	max-width: $local-max-width;
	margin: {
		left: auto;
		right: auto;
	}
}

// Absolute center in relative container
@mixin absolute-center-in-container() {
	width: 50%;
	height: 50%;
	overflow: auto;
	margin: auto;
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;
}

// Break out of container to fullwidth - ugly but works
@mixin extend-fullwidth() {
	padding-left:  3000px;
	padding-right: 3000px;
	margin-left:   -3000px !important;
	margin-right:  -3000px !important;
}

// Flex (Bourbon)
// CSS3 Flexible Box Model and property defaults
// Unified attributes for 2009, 2011, and 2012 flavours.

// 2009 - display (box | inline-box)
// 2011 - display (flexbox | inline-flexbox)
// 2012 - display (flex | inline-flex)
@mixin display($value) {
// flex | inline-flex
	@if $value == "flex" {
	    // 2009
	    display: -webkit-box;
	    display: -moz-box;
	    display: box;

	    // 2012
	    display: -webkit-flex;
	    display: -moz-flex;
	    display: -ms-flexbox; // 2011 (IE 10)
	    display: flex;
	} @else if $value == "inline-flex" {
	    display: -webkit-inline-box;
	    display: -moz-inline-box;
	    display: inline-box;

	    display: -webkit-inline-flex;
	    display: -moz-inline-flex;
	    display: -ms-inline-flexbox;
	    display: inline-flex;
	} @else {
		display: $value;
 	}
}

// 2009 - box-ordinal-group (integer)
// 2011 - flex-order (integer)
// 2012 - order (integer)
@mixin order($int: 0) {
	-webkit-box-ordinal-group: $int;   /* OLD - iOS 6-, Safari 3.1-6 */
 	-moz-box-ordinal-group: $int;      /* OLD - Firefox 19- */
	-ms-flex-order: $int;              /* TWEENER - IE 10 */
	-webkit-order: $int;               /* NEW - Chrome */
	order: $int;
}
