.shop-most-popular {
	margin: 3rem 0 0;

	h2 {
		font-family: 'suisse_semibold';
		font-size: 180%;
		padding: 0 0 0.4em;
		color: $dark-navy;
		text-transform: uppercase;
	}

}
