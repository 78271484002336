.top-nav-wrap {
	position: relative;
	z-index: 102;
	display: none;

	.header.opened & {
		display: block;

		.menu-item {
			a {
				color: $light-gray;
			}
		}
	}

	@include breakpoint( medium ) {
		display: block;
	}

	.top-nav {
		@include breakpoint( medium ) {
			float: right;
			margin-top: 3px;
			width: 100%;
		}

		.menu-item {
			font-size: 85%;
			text-align: left;

			@include breakpoint( medium ) {
				width: auto;
				float: right;
				padding-right: 0;
			}

			a {
				display: block;
				padding: 0.75rem 0;
				margin-right: 0.75em;
			}

			&:first-child a {
				margin-right: 0;
			}
		}
	}
}
