body.woocommerce.single-product {

	h1.product_title {
		font-family: 'suisse_semibold';
		font-size: 225%;
		color: $dark-navy;
		margin-bottom: 1.5rem;
	}

	h2 {
		font-family: 'suisse_semibold';
		font-size: 180%;
		color: $dark-navy;
		margin-bottom: 0.75rem;
	}

	div.product div.summary {
		div[itemprop="offers"] {
			border-radius: 4px 4px 0 0;
			background: $white;
			padding: 1em;
			margin-bottom: 2px;
		}

		.price {
			margin-bottom: 0;
			padding-bottom: 0;
		}

		.cart {
			background: $white;
			padding: 1.3em 1em;
			border-radius: 0 0 4px 4px;

			input[type="number"] {
				margin-bottom: 0;
				padding: 0.5em;
				height: auto;
				line-height: 1em;
			}
		}
	}

	.product_meta > span {
		display: block;
		padding-bottom: 0.5em;

		a {
			font-weight: 700;
		}
	}

	#content div.product div.summary {
		@include breakpoint( medium ) {
			width: 40%;
		}
	}


	#content div.product div.images,
	div.product div.images {
		@include breakpoint( medium ) {
			width: 52.63%;
		}
	}

	div.product div.images {
		ol {
			list-style: none;
			margin-left: 0;
			padding-top: 1em;

			&:after {
				clear: both;
			}

			li {
				width: 13.5%;
				float: left;
				margin-right: 3.8%;
				margin-bottom: 1em;

				&:nth-child(6) {
					margin-right: 0;
				}
			}
		}
	}

	.description {
		clear: both;
		@include breakpoint( medium ) {
			width: 52.63%;
		}
	}

	.related.products {
		clear: both;
		margin-top: 3em;

		h2 {
			text-transform: uppercase;
			margin-bottom: 0.9em;
			font-family: 'suisse_semibold';
			font-size: 120%;
			color: $dark-navy;
		}
	}

}

// Override woocommerce thumbnail sizes - too big on large screens!
body.woocommerce #content div.product div.thumbnails a,
body.woocommerce div.product div.thumbnails a,
body.woocommerce-page #content div.product div.thumbnails a,
body.woocommerce-page div.product div.thumbnails a {
	width: 13.5%;
}
