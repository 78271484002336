.search-wrapper {
	position: relative;

	.inner-grid {
		position: relative;
	}

	.search-icon {
		display: none;

		@include breakpoint( medium ) {
			display: block;
			@include icons( '\f002', 'fontawesome', 'after', $black, '0' );
			position: absolute;
			top: -3.25rem;
			right: 1rem;
			padding: ( 0.5 * $global-padding ) 0;
			z-index: 4;
		}

		@include breakpoint( large ) {
			right: 0;
		}

		&::after {
			font-size: 1.5rem;
		}
	}

	.search-form {
		display: none;

		header.opened & {
			display: block;
			margin-bottom: 0.75rem;

			#searchform {
				width: 100%;
				border-bottom: rem-calc(1) $silver solid;
				padding: 0 0.5rem;
			}

			input {
				display: inline-block;
				margin: 0;
				background: none;
				border: none;
				@include box-shadow( 0, 0, 0, 0, transparent, true );

				&[type=search] {
					width: 85%;
					font-size: 1rem;
					height: rem-calc(40);
					color: $light-gray;
					padding-bottom: 0.25rem;

					@include placeholder {
						color: $gainsboro;
					}
				}

				&[type=submit] {
					font-size: 0;
				}
			}

			.submit-wrapper {
				@include icons( '\f002', 'fontawesome', 'after', $light-gray, '0' );
				width: rem-calc(30);
				position: relative;
				display: inline-block;
				margin: 0;
				vertical-align: top;
				z-index: 1;

				&::after {
					font-size: 1rem;
					display: block;
					position: absolute;
					left: 1rem;
					top: 0.5rem;
				}
			}
		}
	}

	&.fixed {
		position: fixed;
		z-index: 110;
		text-align: center;
		width: 100%;
		height: 100%;

		.search-icon {
			@include icons( '\f00d', 'fontawesome', 'after', $black, '0' );
		}

		.background-wrap {
				background-color: $white;
				position: fixed;
				height: rem-calc(190);
				left: 0;
				width: 100%;
				z-index: 2;
			}

		.search-form {
			display: block;
			position: relative;
			z-index: 3;
			width: 75%;
			margin: 0 auto;
			padding: $global-padding 0;

			#searchform {
				width: 100%;
				border-bottom: rem-calc(1) $gainsboro solid;
				padding: 0.5rem 1.25rem;
			}

			input {
				display: inline-block;
				margin: 0;
				background: none;
				border: none;
				@include box-shadow( 0, 0, 0, 0, transparent, true );

				&[type=search] {
					width: 85%;
					font-size: 2.5rem;
					height: 3.5rem;
					color: $black;

					@include placeholder {
						color: $gainsboro;
					}
				}

				&[type=submit] {
					font-size: 0;
				}
			}

			.submit-wrapper {
				@include icons( '\f002', 'fontawesome', 'after', $black, '0' );
				width: rem-calc(80);
				position: relative;
				display: inline-block;
				margin: 0;
				vertical-align: top;
				z-index: 1;

				&::after {
					font-size: 2.25rem;
					display: block;
					position: absolute;
					left: rem-calc(25);
					top: 0;
				}
			}

		}

		&::after {
			content: '';
			height: 100%;
			width: 100%;
			position: absolute;
			z-index: 1;
			background-color: $white;
			top: 0;
			left: 0;
			opacity: 0.8;
		}
	}
}
