.archive,
.search,
.blog,
body.woocommerce {
	.archive-wrap {
		.archive-header {
			.title-wrap {
				@include grid-row;
			}

			h1 {
				@include grid-column( 9.5 );
				@include grid-column-offset( 2 );

				@include breakpoint( medium ) {
					@include grid-column( 7 );
					@include grid-column-offset( 2 );
				}
			}
		}

		.content-wrap {
			@include grid-column( 12 );

			.article {

				@include breakpoint( medium ) {
					@include grid-row();

					.article-header {
						@include grid-column( 4, 0 );
					}

					.article-content {
						@include grid-column( 8, 0 );
					}
				}
			}
		}
	}

	&.search-results {
		.content-wrap {
			.article {
				@include breakpoint( medium ) {
					.article-header {
						@include grid-column( 4 );
					}

					.article-content {
						@include grid-column( 8 );
					}
				}
			}
		}
	}

	&.post-type-archive-artist,
	&.post-type-archive-exhibition,
	&.post-type-archive-work {
		.content-wrap {
			.article {
				@include grid-row;

				@include breakpoint( medium ) {
					@include grid-column( 4 );
				}

				@include breakpoint( large ) {
					@include grid-column( 3 );
				}

				.article-header,
				.article-content {
					@include grid-column( 12, 0 );
				}
			}
		}
	}

	&.post-type-archive-exhibition {
		.content-wrap {
			.article {
				@include breakpoint( medium ) {
					@include grid-column( 6 );
				}
			}
		}
	}
}
