.widget-feature {
	.home #frontpage & {
		padding-left: 0;
		padding-right: 0;
	}
	> .inner-grid,
	.feature {
		height: 100%;
		width: 100%;
	}

	> .inner-grid {
		max-width: none;
		margin: 0;
	}

	@include breakpoint( medium ) {
		//margin-top: rem-calc(90);
	}

	.feature-wrap {
		@include calc( 'height', '100% - #{rem-calc(90)}' );
		@include calc( 'height', '100vh - #{rem-calc(90)}' );

		.feature {
			position: relative;
			height: 100%;
			@include background-cover;
			background-position: top center;
			width: 100%;
			height: 100%;
			height: 100vh;
			z-index: 1;

			@include breakpoint( medium ) {
				margin-top: rem-calc(90);
				@include calc( 'height', '100% - #{rem-calc(90)}' );
				@include calc( 'height', '100vh - #{rem-calc(90)}' );
				position: fixed;
				left: 0;
			}

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				background: linear-gradient( 77deg, rgba( 0, 0, 0, 0.35 ), transparent );
			}
		}
	}

	.inner-grid > .inner-grid {
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		right: 0;
		top: 0;
	}

	.content {
		position: absolute;
		bottom: $global-margin;
		z-index: 1;
		color: $white;

		@include breakpoint( large ) {
			bottom: rem-calc(150) + $global-margin;
		}

		h4 {
			font-family: 'harriet_bold';
			font-size: 275%;
			letter-spacing: -0.04em;
			line-height: 100%;
			text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
			margin-bottom: 0;

			@include breakpoint( medium ) {
				font-size: 350%;
			}

			@include breakpoint( large ) {
				font-size: 500%;
			}
		}

		p {
			font-size: 125%;
			line-height: 140%;
			font-weight: bold;
			margin: 1em 0 1.5rem;
		}

		.link {
			a {
				color: $white;
				text-transform: uppercase;
				@include icons( '\f105', 'fontawesome', 'after', '', '0 0 0 8px' );
				font-weight: bold;
				font-size: 90%;
				border: rem-calc(2) solid $white;
				padding: 0.75rem 1.75rem;
				display: inline-block;
			}
		}
	}
}
