.single-post {
	background: $white;

	.header {
		background-color: #ececec;
		border-bottom: 1px solid #dadada;
	}
	#main-nav {
		background-color: transparent;
		.menu {
			background-color: inherit;
			> .menu-item {
				background-color: inherit;
			}
		}
	}

	&.custom-background {
		background-image: none !important;

		#container {
			background: none;
		}
	}

	.article {
		.content-wrap {
			margin-bottom: $global-margin;
			z-index: 9;
			position: relative;
			@include breakpoint( small down ) {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.article-title {
			font-family: 'harriet_bold';
			letter-spacing: -0.04em;
			font-size: 180%;
			line-height: 100%;
			margin-bottom: $global-margin;
			margin-top: $global-margin + rem-calc(40);
			@include breakpoint( medium ) {
				font-size: 450%;
			}
		}

		.article-categories,
		.article-tags {
			font-size: 90%;
		}

		.categories-title,
		.tags-title {
			display: inline-block;
			font-size: 0.9rem;

			&::after {
				content: ': ';
				display: inline-block;
			}
		}

		.article-meta {
			margin: 0.75rem 0 1.75rem 0;
		}

		.author-wrap,
		.date {
			font-style: italic;
		}
	}

	.article {
		#sidebar-posts {
			margin-top: 8px;
			@include breakpoint( small down ) {
				padding-left: 0;
				padding-right: 0;
				.inner-grid {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}
	.before-footer {
		.museums-wrap {
			> .inner-grid {
				margin-bottom: 0;
				@include breakpoint( small down ) {
					margin-right: 0;
					margin-left: 0;
				}
			}
		}
	}
}
