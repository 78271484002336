.home {
	#content {
		max-width: 71.25rem;
		margin-left: auto;
		margin-right: auto;
	}
	#frontpage {
		@include breakpoint( medium ) {
			max-width: none;
			margin-left: -50px;
			margin-right: -50px;
		}
		.widget {
			width: 100%;
			position: relative;
			z-index: 3;
			padding-left: 10px;
			padding-right: 10px;
			float: left;
			.inner-grid {
				width: 100%;
			}
		}

		.widget-feature {
			width: 100%;
			height: 70%;
			height: 70vh;
			min-height: 32em;
			position: relative;
			z-index: 1;
			.inner-grid {
				.inner-grid {
					margin: 0;
					padding-left: 1rem;
					padding-right: 1rem
				}
			}

			@include breakpoint( large ) {
				@include calc( 'height', '100% - #{rem-calc(90)}' );
				@include calc( 'height', '100vh - #{rem-calc(90)}' );
				min-height: 42em;
			}
		}

		.widget:first-child {
			position: relative;
			margin-bottom: $global-padding;

			@include breakpoint( large ) {
				margin-bottom: - rem-calc(120);
			}
		}

		.widget-teaser {
			z-index: 4;
			@include breakpoint( medium ) {
				width: 50% !important;
			}
		}

	}

	.main {
		margin-top: 0;
	}

	.before-footer .museums-wrap {
		.inner-grid {
			max-width: 76.35rem !important;
		}
	}
}
