.mini-cart-wrap {
	position: fixed;
	width: 100%;
	left: 0;
	right: 0;
	top: 20px;
	z-index: 99;
	.admin-bar & {
		top: 58px;
	}
	@include breakpoint( medium ) {
		top: 46px;
		.admin-bar & {
			top: 78px;
		}
	}
}
.inner-grid-mini-cart {
	position: relative;
}

.mini-cart {
	position: absolute;
	top: 0;
	right: 85px;
	@include breakpoint( medium ) {
		right: 30px;
	}
	&:hover {
		@include breakpoint( medium ) {
			.mini-cart-content {
				display: block;
			}
		}
	}
	.inner-grid {
		position: relative;
	}

	.mini-cart-content {
		position: absolute;
		right: 0;
		width: 300px;
		display: none;
		background-color: #fff;
		padding: 20px;
		.mini_cart_item {
			&:first-child {
				padding-top: 0;
			}
		}
	}

	.cart-contents {
		display: block;
		border: 1px solid #fff;
		background: rgba(0, 0, 0, 0.2);
		color: #ffffff;
		padding: 7px 10px 6px 15px;

		.fa-shopping-basket {
			font-size: 20px;
		}

		@include breakpoint( medium ) {
			right: 50px;
			top: -44px;
			display: block;
			padding: 10px 10px 10px 20px;
			border: none;
			background: transparent;
			color: #000000;
		}
	}

	.item-count {
		background-color: $ocher;
		display: inline-block;
		padding: 1px;
		line-height: 14px;
		font-size: 12px;
		font-weight: bold;
		color: #fff;
		vertical-align: top;
		text-align: center;
		margin-left: -10px;
		border-radius: 50%;
		width: 16px;
		height: 16px;
	}
	.woocommerce & {
		ul.cart_list {
			position: relative;
			max-height: 300px;
			overflow-y: auto;
			li {
				position: relative;
				border-bottom: 1px solid #e5e5e5;
				padding-left: 20px;
				&:first-child {
					a.remove {
						top: -5px;
					}
				}
				a.remove {
					position: absolute;
					left: -6px;
					top: 0px;
					color: #394448 !important;
					&:hover {
						background: transparent;
						color: #e5e5e5 !important;
					}
				}
				a {
					line-height: 1.2;
				}
			}
		}
	}
	.total {
		padding-top: 10px;
	}
	.buttons {
		margin-bottom: 0;
		.button {
			color: #ffffff;
			background-color: #be974c;
			&:hover {
				color: #ffffff;
				background-color: #b08a40;
			}
		}
	}
}