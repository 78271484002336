.widget-teaser {

	&.default,
	&.product {
		.featured {
			width: 100%;
			height: rem-calc(170);
			@include background-cover;
			@include border-top-radius( 4px );
			overflow: hidden;
		}

		.label {
			display: none;
		}

		.content {
			background: $white;
			@include border-bottom-radius( 4px );
			overflow: hidden;
			@include box-shadow( 0, 1px, 1px, 1px, rgba( 0, 0, 0, 0.04 ) );
			padding: 0.75rem 1.25rem 1.25rem;
			font-size: 1rem;
			font-family: $body-font-family;

			h4 {
				font-family: 'suisse_semibold';
				font-size: 150%;
				line-height: 115%;
			}

			p {
				font-size: 90%;
				line-height: 150%;
				margin: 0.75rem 0;
			}

			a {
				@include icons( '\f105', 'fontawesome', 'after', '', '0 0 0 8px' );
				color: $ocher;
			}
		}
	}

	&.default {
		.has-no-image {
			.featured {
				display: none;
			}

			&, h4 {
				color: $white;
			}

			a {
				font-weight: bold;
			}

			.content {
				@include border-top-radius( 4px );
				background: $charcoal;
				padding: 1.25rem;
			}
		}

		.menu-widgets & {
			.teaser {
				height: rem-calc(330);
			}

			.featured {
				height: 100%;
				background-size: contain;
				background-position: center right;
			}

			.content {
				padding: 0;
				box-shadow: none;

				h4 {
					display: none;
				}

				.link a {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;

					&::after {
						display: none;
					}
				}
			}
		}
	}

	&.product {
		.content {
			.menu-widgets & {
				height: rem-calc(150);

				a::after {
					float: none;
				}
			}
		}
	}

	&.event {
		position: relative;

		.inner-grid,
		.widget-wrap {
			width: 100%;
			height: rem-calc(270);
			@include border-top-radius( 4px );
			@include border-bottom-radius( 4px );
			overflow: hidden;
			position: relative;
		}

		.featured {
			width: 100%;
			height: 100%;
			position: absolute;
			@include background-cover;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient( 0deg, rgba( 0, 0, 0, 0.45 ), transparent );
				z-index: 2;
			}
		}

		.content {
			padding: 1.25rem;
			position: absolute;
			z-index: 3;
			bottom: 0;

			&, a {
				color: $white;
			}

			h4 {
				font-family: 'harriet_bold';
				font-size: 175%;
				letter-spacing: -0.04em;
				line-height: 120%;
				text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
			}

			.meta {
				font-size: 90%;
				line-height: 150%;
				font-weight: bold;
				margin: 0.75rem 0;
			}

			.link a {
				color: $white;
				text-transform: uppercase;
				@include icons( '\f105', 'fontawesome', 'after', '', '0 0 0 8px' );
				font-weight: bold;
				font-size: 70%;
				border: rem-calc(2) solid $white;
				padding: 0.5rem 1.25rem;
				display: inline-block;
			}
		}
	}

	.home #frontpage & {
		&.default,
		&.event,
		&.product {
			//margin: 0 1rem;
			margin-bottom: $global-padding;

			@include breakpoint( large ) {
				@include calc( 'width', '50% + ( ( 1.25 * #{$global-margin} ) ) - 0.51rem' );
			}

			.inner-grid {
				padding: 0px 0.4rem;

				@include breakpoint( medium ) {
					background: $gainsboro;
					width: auto;
					overflow: visible;
					padding: 0 1rem;
					padding-top: 0.75 * $global-margin;
					padding-bottom: 0.75 * $global-margin;
				}

				@include breakpoint( large ) {
					@include border-top-radius( 4px );
					@include border-bottom-radius( 4px );
					padding: 1.25 * $global-margin;
					max-width: none !important;
				}
			}
		}

		&.event {
			.inner-grid {
				height: rem-calc(350);
			}

			.featured {
				height: 100%;
				width: 100%;

				@include breakpoint( medium ) {
					@include calc( 'width', '100% - ( 2 * 1.25 * #{$global-margin} )' );
					@include calc( 'height', '100% - ( 2 * 0.75 * #{$global-margin} )' );
				}

				@include breakpoint( large ) {
					@include calc( 'height', '100% - ( 2 * 1.25 * #{$global-margin} )' );
				}
			}

			.content {
				bottom: 1.5 * $global-margin;
			}
		}

	}

}

.home #frontpage {
	.widget.owl_widget {
		padding-left: 1rem;
		padding-right: 1rem;
		@include breakpoint( medium ) {
			padding-left: 0.6rem;
			padding-right: 0.6rem;
		}
		.inner-grid {
			margin: 0;
		}
	}
	.widget.widget-calendar {
		padding-left: 1rem;
		padding-right: 1rem;
		@include breakpoint( medium ) {
			padding-left: 0.6rem;
			padding-right: 0.6rem;
		}
	}
}
