.related {
	margin-bottom: $global-padding;

	.inner-grid {
		background: $white;
		@include border-top-radius( 4px );
		@include border-bottom-radius( 4px );
		padding: 0.5 * $global-margin;
		padding-top: 0.75 * $global-margin;

		@include breakpoint( medium ) {
			padding: $global-margin;
		}

		@include breakpoint( large ) {
			@include calc( 'width', '#{$global-width} + #{rem-calc(100)}' );
			max-width: none !important;
			overflow: hidden;
		}

		.single-work & {
			background: $gainsboro;
		}
	}

	.widget-title {
		margin: 0 0.5rem 1rem;

		@include breakpoint( medium ) {
			margin-left: 0.666em;
		}

		h4, span {
			display: inline-block;
			font-family: 'suisse_semibold';
			text-transform: uppercase;
			font-size: 180%;
			color: $dark-navy;
			margin-bottom: 0;
		}

		span {
			color: $ocher;
			font-weight: bold;
			display: inline-block;
			padding: 0 0.5rem;
		}

		a {
			color: $ocher;
			font-size: 100%;
			display: inline-block;
			font-family: $body-font-family;
			@include icons( '\f105', 'fontawesome', 'after', '', '0 0 0 8px' );
		}
	}

	.work,
	.artist {
		overflow: hidden;
		position: relative;
		margin-bottom: $global-padding;

		@include breakpoint( medium ) {
			margin-bottom: 0;
		}

		&.thumb {
			.content {
				position: absolute;
				top: 0;
				left: 0;
				padding: 0.5 * $global-margin;
				width: 100%;
				text-align: center;
				z-index: 2;
				display: none;

				&, a {
					color: $white;
				}

				.title,
				.related-artist {
					margin-bottom: 0.5rem;
					font-weight: bold;
				}

				.link a {
					border-bottom: $white rem-calc(1) solid;
				}
			}

			&:hover {
				.content {
					display: block;
				}

				&::before {
					content: '';
					position: absolute;
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					z-index: 1;
					border-left: rem-calc(15) $white solid;
					border-right: rem-calc(15) $white solid;
					background: $ocher;
					opacity: 0.75;
				}
			}
		}

		&.box {
			.featured {
				width: 100%;
				height: rem-calc(175);
				@include background-cover;
				@include border-top-radius( 4px );
				overflow: hidden;
			}

			.content {
				background: $white;
				@include border-bottom-radius( 4px );
				overflow: hidden;
				@include box-shadow( 0, 1px, 1px, 1px, rgba( 0, 0, 0, 0.04 ) );
				padding: 0.75rem 1.25rem 1.25rem;
			}

			.title {
				margin-top: 0.5rem;

				a {
					font-family: 'suisse_semibold';
					font-size: 150%;
					color: $dark-navy;
				}
			}

			.artist,
			.meta {
				font-family: 'suisse_semibold';
				color: $dark-navy;
			}

			.excerpt {
				font-size: 90%;
				margin-top: 0.5rem;
			}
		}
	}

	.artist {
		&.box {
			.featured {
				height: rem-calc(245);
			}
		}
	}
}
