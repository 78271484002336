.cc-cookies {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(255,255,255, 0.9);
    width: 100%;
    padding: 20px 5%;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
    text-align: center;
    @include breakpoint( medium ) {
        text-align: left;
    }
    .cc-cookie-accept {
        display: inline-block;
        background: #be974c;
        color: #ffffff;
        line-height: 1;
        padding: .618em 1em;
        font-weight: 700;
        border-radius: 3px;
        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -ms-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
        margin-top: 10px;
        margin-left: 0px;
        @include breakpoint( medium ) {
            margin-top: 0px;
            margin-left: 10px;
            float: right;
        }
        &:hover {
            background: #b08a40;
        }
    }
    > a {
        margin-left: 10px;
    }
}