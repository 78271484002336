.before-footer {
	position: relative;
	z-index: 10;

	.museums-wrap {
		.single & {
			background: $gainsboro;
			.inner-grid {
				background-color: inherit;
			}
		}
		.inner-grid {
			background: $white;
			padding: 1rem;
			padding-top: 0.75 * $global-margin;
			padding-bottom: 0.75 * $global-margin;
			margin-bottom: $global-padding;
			.single-product & {
				margin-bottom: 0;
			}

			@include breakpoint( medium ) {
				padding: 1.25 * $global-margin;
			}

			@include breakpoint( large ) {
				@include border-top-radius( 4px );
				@include border-bottom-radius( 4px );
				@include calc( 'width', '#{$global-width} + #{rem-calc(100)}' );
				max-width: none !important;
				overflow: hidden;
			}
		}

		.widget-title {
			margin-bottom: 1rem;

			h4, span {
				display: inline-block;
				font-family: 'suisse_semibold';
				text-transform: uppercase;
				font-size: 180%;
				color: $dark-navy;
				margin-bottom: 0;
			}

			span {
				color: $ocher;
				font-weight: bold;
				display: none;
				padding: 0 0.5rem;

				@include breakpoint( medium ) {
					display: inline-block;
				}
			}

			a {
				color: $ocher;
				font-size: 100%;
				display: inline-block;
				font-family: $body-font-family;
				@include icons( '\f105', 'fontawesome', 'after', '', '0 0 0 8px' );
			}
		}

		.museum {
			@include breakpoint( medium down ) {
				margin-bottom: 0.5 * $global-margin;
			}

			a {
				display: block;
			}

			.title {
				font-family: 'suisse_semibold';
				font-size: 210%;

				img {
					height: rem-calc(50);
					width: auto;
					margin-left: - rem-calc(1);
				}
			}

			.meta {
				> div {
					a {
						color: $ocher;
						display: inline-block;
						border-bottom: rem-calc(1) solid $ocher;
					}

					@include breakpoint( medium ) {
						margin-top: 0.35 * $global-padding;
					}
				}

				.address {
					@include icons( '\f041', 'fontawesome', 'before', $black, '0' );
					&::before {
						display: inline-block;
						width: 1.5em;
					}
				}

				.opening {
					@include icons( '\f017 ', 'fontawesome', 'before', $black, '0' );
					&::before {
						display: inline-block;
						width: 1.5em;
					}
				}
			}
		}
	}
}

.footer {
	background: $charcoal;
	padding: ( 1.5 * $global-margin ) 0;
	color: $gainsboro;
	position: relative;
	z-index: 10;

	a {
		color: $ocher;
	}

	.inner-grid {
		position: relative;

		.widget-area .widget {
			.inner-grid {
				margin: 0 1rem;
				text-align: center;

				@include breakpoint( medium ) {
					text-align: left;
				}

				@include breakpoint( large ) {
					margin: 0;
				}
			}

			&:first-child {
				padding-left: 0;
			}
			&:first-child,
			&:nth-child(2) {
				.menu > li {
					a {
						padding-left: 0;
					}

					&:first-child > a {
						padding-top: 0.25rem;
					}
				}
			}
			&:nth-last-child(2) {
				padding-right: 1rem;
				padding-top: rem-calc(25);

				a {
					font-family: 'suisse_semibold';
					font-size: 235%;
					padding: 0;
					padding-bottom: 0.35rem;
				}

				@include breakpoint( medium ) {
					padding-top: rem-calc(100);

					.inner-grid {
						text-align: right;
					}
				}

				@include breakpoint( large ) {
					padding-right: 0;
				}
			}
			&:last-child {
				@include breakpoint( medium ) {
					position: absolute;
					top: 0;
					right: 1rem;
					padding: 0;

					.inner-grid {
						text-align: right;
					}
				}

				li {
					display: inline-block;
					margin-right: 0.25rem;

					a {
						color: $silver;
						font-size: 0;
						height: rem-calc(35);
						width: rem-calc(35);
						padding: 0.45rem 0.45rem 0.3rem;
						@include border-bottom-radius( 50% );
						@include border-top-radius( 50% );

						&::after {
							font-size: 2rem;
						}
					}

					&.facebook {
						margin-right: 0;
						a {
							@include icons( '\f09a', 'fontawesome', 'after', '', '0' );
							&::after {
								position: relative;
								top: 0.1em;
								font-size: 1.75rem;
							}
						}
					}
					&.twitter a {
						@include icons( '\f099', 'fontawesome', 'after', '', '0' );
					}
					&.instagram a {
						@include icons( '\f16d', 'fontawesome', 'after', '', '0' );
					}
					&.flickr a {
						@include icons( '\f16e', 'fontawesome', 'after', '', '0' );
					}
					&.youtube a {
						@include icons( '\f167', 'fontawesome', 'after', '', '0' );
					}
					&.pinterest a {
						@include icons( '\f0d2', 'fontawesome', 'after', '', '0' );
					}
					&.ellipsis a {
						@include icons( '\f141', 'fontawesome', 'after', '', '0' );
					}
					&.tripadvisor a {
						@include icons( '\f262', 'fontawesome', 'after', '', '0' );
					}
				}

				@include breakpoint( large ) {
					right: 0;
				}
			}
		}
	}
}

.after-footer {
	display: none;
}
