.archive,
.search,
.blog,
body.woocommerce {

	@include breakpoint( medium ) {
		padding-top: rem-calc(90);
	}
	.main {
		margin: 0;
		z-index: 10;

		.archive-wrap {
			padding-top: 11.625rem;

			@include breakpoint( medium ) {
				padding-top: rem-calc(300);
			}

			> .inner-grid {
				background: $gainsboro;
				@include border-top-radius( 4px );
				@include border-bottom-radius( 4px );
				padding: 1rem;
				margin-bottom: $global-padding;

				@include breakpoint( large ) {
					@include calc( 'max-width', '#{$global-width} + #{rem-calc(100)}' );
					overflow: hidden;
					padding: 1.25 * $global-margin;
					padding-top: 0.75 * $global-margin;
				}
			}

			.post-image-meta {
				display: none;
			}

			.archive-header {
				margin-bottom: $global-padding;

				h1 {
					font-family: 'harriet_bold';
					font-size: 225%;
					line-height: 100%;
					text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
					color: $white;
					letter-spacing: -0.04em;

					@include breakpoint( medium ) {
						font-size: 350%;
					}

					@include breakpoint( large ) {
						font-size: 500%;
					}
				}

				.title-wrap {
					position: relative;
				}
			}
		}

		.article {
			padding: 1rem 0;
			border-bottom: rem-calc(1) solid $silver;

			&:last-child {
				border: none;
			}

			.article-content {
				padding: 0.333rem 0;

				h2 {
					&, a {
						font-family: 'suisse_semibold';
						font-size: 150%;
						color: $dark-navy;
					}
				}
			}

			.article-meta {
				font-weight: bold;

				a {
					color: $charcoal;
				}
				.museum {
					margin-top: 0.5rem;
				}
			}
		}
	}

	#search-form {
		padding-top: $global-padding;

		@include breakpoint( medium ) {
			padding: $global-padding ( 0.75 * $global-margin );
		}

		input[type="search"] {
			border-color: $silver;
		}
	}

	&.post-type-archive-artist,
	&.post-type-archive-exhibition,
	&.post-type-archive-work {
		.content-wrap {
			.article {
				border-bottom: none;

				.article-header {
					.wp-post-image-wrap {
						.post-image {
							img {
								margin: 0;
								width: 100%;
								height: auto;
							}
						}
					}
				}

				.article-content {
					background: $white;
					@include border-bottom-radius( 4px );
					@include box-shadow( 0, 1px, 1px, 1px, rgba( 0, 0, 0, 0.04 ) );
					padding: 0.75rem 1.25rem 0.75rem;
					font-family: $body-font-family;
					position: relative;
				}
			}
		}
	}

	&.post-type-archive-artist,
	&.post-type-archive-event,
	&.post-type-archive-exhibition,
	&.post-type-archive-work,
	&.post-type-archive-news {
		.archive-footer {
			display: none;
		}
	}

	&.post-type-archive-artist,
	&.post-type-archive-work,
	&.post-type-archive-event,
	&.post-type-archive-news {
		.content-wrap {
			.article {
				.article-content {
					h2, h2 a {
						font-family: 'suisse_semibold';
						font-size: 120%;
						color: $dark-navy;
						margin-top: 0;
					}

					.meta {
						font-family: 'suisse_semibold';
						color: $dark-navy;
					}

					p {
						margin-bottom: 0.4em;
					}
				}
			}
		}
	}

	&.post-type-archive-exhibition {
		.content-wrap {
			.upcoming-exhibitions-title {
				clear: both;
				position: relative;
				overflow: hidden;

				h2 {
					background-color: $gainsboro;
					padding-top: 1rem;
					padding-left: 1rem;
					padding-right: 1rem;
					margin-bottom: 0;
					font-family: suisse_semibold;
					text-transform: uppercase;
					font-size: 29px;
					color: #24343c;
				}
			}

			.article {
				.article-content {
					h2, h2 a {
						position: absolute;
						@include calc( 'width', '100% - 2 * 1.25rem' );
						bottom: 120%;
						font-family: 'harriet_bold';
						letter-spacing: -0.04em;
						font-size: 125%;
						line-height: 100%;
						text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
						color: $white;

						@include breakpoint( medium ) {
							font-size: 150%;
						}

						@include breakpoint( large ) {
							font-size: 175%;
						}

						.article-content-inner {
							display: flex;
							flex-direction: column;

							.article-meta {
								order: 1;
								margin-top: 0.5rem;
								margin-bottom: 0.75rem;
							}

							.article-excerpt {
								order: 2;
								margin: 0;
							}
						}
					}
				}

				&.type-exhibition {
					.article-content {
						h2 {
							font-size: 175%;
							letter-spacing: -0.02em;
						}
					}
				}
			}
		}
	}

	&.post-type-archive-work {
		.content-wrap {
			.article {
				.article-excerpt {
					display: none;
				}
			}
		}
	}

	&.post-type-archive-event,
	&.post-type-archive-news {
		.content-wrap {
			.article {

				header {

					a {
						display: block;
						padding-bottom: 0.333em;

						@include breakpoint( medium ) {
							padding: 0.333em 1em 0.333em 0;
						}
					}
				}

				img {
					margin: 0;
				}
			}
		}
	}
}

.post-type-archive-exhibition {
	.archive-content {
		> h2 {
			background-color: $gainsboro;
			padding-top: 3.125rem;
			padding-left: 3.125rem;
			padding-right: 3.125rem;
			margin-bottom: 0;
			font-family: suisse_semibold;
			text-transform: uppercase;
			font-size: 29px;
			color: #24343c;
			-webkit-border-top-left-radius: 4px;
			border-top-left-radius: 4px;
			-webkit-border-top-right-radius: 4px;
			border-top-right-radius: 4px;

			@include breakpoint( small down ) {
				padding-left: 1.25rem;
				padding-right: 1.25rem;
			}
		}
	}
	&.archive .main .archive-wrap > .inner-grid {
		background-color: transparent;
		padding: 0;
		padding-left: 10px;
		padding-right: 10px;
	}

	&.archive .archive-wrap .content-wrap {
		background-color: $gainsboro;
		padding: 3.125rem - 0.9375rem;
		padding-left: 3.125rem - 0.9375rem;
		padding-right: 3.125rem - 0.9375rem;
		margin-bottom: 1.875rem;
		-webkit-border-radius: 4px;
		border-radius: 4px;

		@include breakpoint( small down ) {
			padding-left: 0;
			padding-right: 0;
		}

		&:first-of-type {
			padding-top: 1rem - 0.9375rem;
			-webkit-border-top-left-radius: 0;
			border-top-left-radius: 0;
			-webkit-border-top-right-radius: 0;
			border-top-right-radius: 0;
		}
	}

	.before-footer .museums-wrap {
		padding-left: 10px;
		padding-right: 10px;
	}

	.before-footer .museums-wrap .inner-grid {
		max-width: 100% !important;
	}

	@include breakpoint( medium ) {
		.before-footer .museums-wrap .inner-grid {
			width: -webkit-calc(71.25rem + 6.25rem - 1rem);
			width: calc(71.25rem + 6.25rem - 1rem);
		}
	}
}
