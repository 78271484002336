.page:not(.home),
.error404,
.single-artist,
.single-event,
.single-exhibition,
.single-museum,
.single-work {

	@include breakpoint( large ) {
		padding-top: rem-calc(90);
	}

	.main {
		margin: 0;
		z-index: 10;

		.article {
			position: relative;
			padding-top: rem-calc(300);

			> .inner-grid {
				background: $gainsboro;
				@include border-top-radius( 4px );
				@include border-bottom-radius( 4px );
				padding: 0.75 * $global-margin;
				padding-top: 0.75 * $global-margin;
				margin-bottom: $global-padding;

				@include breakpoint( medium ) {
					padding: 1.25 * $global-margin;
				}

				@include breakpoint( large ) {
					@include calc( 'width', '#{$global-width} + #{rem-calc(100)}' );
					max-width: none !important;
				}
			}

			.article-title {
				margin-bottom: $global-padding;

				h1 {
					font-family: 'harriet_bold';
					font-size: 180%;
					letter-spacing: -0.04em;
					line-height: 100%;
					text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
					color: $white;

					@include breakpoint( medium ) {
						font-size: 350%;
					}

					@include breakpoint( large ) {
						font-size: 500%;
					}
				}

				.title-wrap {
					position: absolute;
					bottom: 0;
				}

				.subtitle {
					font-family: 'suisse';
					font-weight: bold;
					font-size: 100%;
					line-height: 1;
					color: $white;

					@include breakpoint( medium ) {
						margin-top: 0.1 * $global-padding;
						font-size: 150%;
					}
				}

				.reviews-wrap {
				}

				.review {
					color: $white;
					margin-top: 0.25rem;

					@include breakpoint( small down ) {
						font-size: 0.8em;
					}

					.rating {
						display: inline-block;

						@include breakpoint( medium ) {
							width: rem-calc(135);
							text-align: left;
						}

						.fa-star,
						.fa-heart {
							margin-right: rem-calc(4);
						}

						.empty {
							opacity: 0;
						}
					}

					.media {
						font-weight: bold;
						padding-right: rem-calc(8);
					}
				}
			}

			.article-excerpt {
				font-weight: bold;
			}

			.ticket {
				@include border-top-radius( 4px );
				@include border-bottom-radius( 4px );
				overflow: hidden;
				background: $white;
				@include clearfix;
				@include box-shadow( 0, 1px, 1px, 1px, rgba( 0, 0, 0, 0.04 ) );
				margin-top: $global-margin;
				margin-bottom: $global-margin;
				position: relative;
				padding: 1.25rem 1.25rem 2rem;

				.content {
					@include breakpoint( medium ) {
						@include grid-column(7);
					}

					h4 {
						color: $dark-navy;
						font-weight: bold;
						font-size: 125%;
					}
				}
				.cta {
					@include breakpoint( medium ) {
						@include grid-column(5);
					}

					a {
						display: block;
						background: $ocher;
						color: $white;
						text-transform: uppercase;
						border: rem-calc(2) solid lighten( $ocher, 10% );
						text-align: center;
						font-weight: bold;
						padding: 0.5rem;
						margin-top: 2rem;
					}
				}
			}

			.connected {
				@include border-top-radius( 4px );
				@include border-bottom-radius( 4px );
				overflow: hidden;
				background: $white;
				@include clearfix;
				@include box-shadow( 0, 1px, 1px, 1px, rgba( 0, 0, 0, 0.04 ) );
				margin-top: $global-margin;
				position: relative;

				.featured {
					@include background-cover;
					width: rem-calc(150);
					min-height: rem-calc(175);
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
				}
				.content {
					width: 100%;
					padding: 1.25rem;
					padding-left: rem-calc(150) + 1.25rem;

					.title {
						a {
							color: $dark-navy;
							font-weight: bold;
							font-size: 125%;
						}
					}

					.meta {
						margin-bottom: 0.75rem;
						color: $dark-navy;
						font-weight: bold;
					}
				}
			}

			&.has-post-thumbnail {
				padding-top: 0;

				> .content {
					margin-top: - rem-calc(120);
					position: relative;
					z-index: 10;

					@include breakpoint( large ) {
						margin-top: - rem-calc(230);
					}
				}

				.article-title {
					position: absolute;
					width: 79%;
					top: -20px;
					z-index: 1;
					margin-bottom: 0;
				}

				.inline-featured {
					margin-bottom: $global-margin;

					img {
						margin-top: 0;
					}
				}
			}
		}
	}
}

.single-work, .single-artist {
	.sidebar {
		padding-bottom: rem-calc(20);
	}
}