.widget-calendar {
	margin-bottom: $global-padding;

	.widget-area & {
		.inner-grid {
			background: $gainsboro;
			width: 100%;
			overflow: hidden;
			padding: ( 1.25 * $global-margin ) 1rem;
			padding-bottom: 0;

			@include breakpoint( medium ) {
				padding-top: 0.75 * $global-margin;
				padding-bottom: 0.75 * $global-margin;
			}

			@include breakpoint( large ) {
				@include border-top-radius( 4px );
				@include border-bottom-radius( 4px );
				@include calc( 'width', '#{$global-width} + #{rem-calc(100)}' );
				padding: 1.25 * $global-margin;
				max-width: none !important;
			}

			.event-wrap {
				@include breakpoint( medium ) {
					max-width: none !important;
				}
			}
		}
	}

	.widget-title {
		margin-bottom: 1rem;

		h4, span {
			display: inline-block;
			font-family: 'suisse_semibold';
	        text-transform: uppercase;
	        font-size: 180%;
			color: $dark-navy;
			margin-bottom: 0;
		}

		span {
			color: $ocher;
			font-weight: bold;
			display: inline-block;
			padding: 0 0.5rem;
		}

		a {
			color: $ocher;
			font-size: 100%;
			display: inline-block;
			font-family: $body-font-family;
			@include icons( '\f105', 'fontawesome', 'after', '', '0 0 0 8px' );
		}

		.menu-widgets & {
			display: none;
		}
	}

	.event {
		margin-bottom: $global-margin;

		@include breakpoint( medium ) {
			margin: 0;
		}

		.featured {
			width: 100%;
			height: rem-calc(150);
			@include background-cover;
			@include border-top-radius( 4px );
			overflow: hidden;
		}

		.content {
			background: $white;
			@include border-bottom-radius( 4px );
			overflow: hidden;
			@include box-shadow( 0, 1px, 1px, 1px, rgba( 0, 0, 0, 0.04 ) );
			padding: 0.75rem 1.25rem 1.25rem;
			font-size: 1rem;
			font-family: $body-font-family;

			.menu-widgets & {
				height: rem-calc(150);
			}
		}

		.meta {
			font-size: 90%;

			a {
				color: $ocher;
			}
		}

		.title {
			margin-top: 0.5rem;

			a {
				font-family: 'suisse_semibold';
				font-size: 150%;
				color: $dark-navy;
				line-height: 120%;
			}
		}
	}
}
