body.woocommerce-page.woocommerce-checkout {

	h3 {

		&:not(#ship-to-different-address) {
			font-family: 'suisse_semibold';
			font-size: 180%;
			padding: 0 0 0.4em;
			color: $dark-navy;
		}

		label {
			display: inline-block;
		}

		input {
			display: inline-block;
		}
	}

	#customer_details .col-1, #customer_details .col-2 {
		margin-bottom: 2em;
	}

	ul.wc_payment_methods {
		margin-left: 0;

		li {
			list-style-type: none;

			label {
				padding-left: 0;

				&[for="payment_method_paypal"] img, .about_paypal {
					display: none;
				}
			}
		}
	}

	input#place_order {
		font-size: 1.25em;
		padding: 1em;
		min-width: 200px;
	}

	.mc4wp-checkbox-woocommerce {
		clear:left; 
		label {
			 > span {
				margin-left:0.25rem;
				margin-right: 1rem;
			}
		}
	}

}

body.woocommerce-page {
	#content > div > .wp-post-image-wrap {
		@include calc('height', '100% - #{rem-calc(500)}');
		@include calc('height', '100vh - #{rem-calc(500)}');
	}

	&.page:not(.home) .main .article.has-post-thumbnail {
		position: relative;
		.article-title {
			top: -4.5rem;
			@include breakpoint( medium ) {
				top: -7.5rem;
			}
		}
	}

	#logo {
		top: 20px;
	}
	@media screen and (min-width: 48.063em) {
		#logo {
			top: 40px;
		}
	}
}