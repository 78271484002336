.header {
	position: fixed;
	z-index: 98;
	width: 100%;
	top: 0;
	text-align: right;
	@media (max-width: 769px) {
		overflow: hidden;
		max-height: 0;
		opacity: 0;
	}

	.top-nav-wrap {
		@include order(4);
	}

	.top-bar {
		@include order(2);
	}

	.top-bar-right li > ul {
		display: none;
	}

	.search-wrapper {
		@include order(3);
	}

	@include breakpoint( medium ) {
		display: block;
		height: auto;
		background: none;
		overflow: visible;
	}

	&.opened {
		position: relative;
		@include display(flex);
		flex-direction: column;
		-webkit-flex-direction: column;
		background: $gainsboro;
		max-height: 2000px;
		opacity: 1;
		-webkit-transition: all .5s ease-in-out;
		transition: all .5s ease-in-out;
	}

	.admin-bar & {
		top: rem-calc(46);

		@include breakpoint(medium) {
			top: rem-calc(32);
		}
	}

	@include breakpoint( medium ) {
		height: rem-calc(90);
		background-color: $white;
		border-bottom: rem-calc(1) solid $silver;
		text-align: left;
	}

	a {
		color: $charcoal;
	}
}

.inner-grid-logo {
	position: relative;
}

.logo-wrap {
	position: fixed;
	z-index: 10;
	left: 0;
	right: 0;

	.header.opened & {
		display: none;
	}
}

#logo {
	position: absolute;
	top: 20px;
	left: 0;
	background: url( ../../img/logo.png );
	background-size: contain;
	background-repeat: no-repeat;
	width: rem-calc(35);
	height: rem-calc(130);
	z-index: 0;

	body.woocommerce & {
		top: -4.1625rem;
	}

	.title {
		font-size: 0;
	}

	a {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}

	.single-post & {
		background: url( ../../img/logo-variant.png );
		background-size: contain;
		background-repeat: no-repeat;
		margin-left: 0;
	}

	@include breakpoint( medium ) {
		top: 130px;
		width: rem-calc(50);
		height: rem-calc(180);
		z-index: 99;
	}

	@include breakpoint( large ) {
		top: 40px;
		width: rem-calc(75);
		height: rem-calc(265);
		margin-left: 0.5rem;
	}

	.home & {
		@include breakpoint( large ) {
			top: 130px;
		}
	}
}
