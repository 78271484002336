// Responsive embeds with CSS
// -------------------------------------------/

.embed-container {
	overflow: hidden;
	max-width: 100%;
	margin: 1.5rem 0;
}

.wp-post-oembed-wrap > .embed-container {
	margin: 0;
}

.home .embed-container {
	margin-bottom: 0;
}

.article-content > .embed-container:first-of-type {
	margin-top: 0.5rem;
}

.embed-container .embed-container-inner {
	position: relative !important;
	padding-bottom: 56.25% !important;
	height: 0 !important;
	overflow: hidden !important;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
}


// Buttons
// -------

.back-button {
	@include icons("\f104");
	margin-bottom: 1em;

	&:before {
		margin-left: 0;
	}
}

// responsive embeds

.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;

	> iframe {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}

	&--16x9 {
		padding-bottom: 56.25%;
	}
}