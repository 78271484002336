.widget {

	&.widget-feature {
		.content {
			@include grid-row;
			@include grid-column-offset( 2 );
			@include breakpoint( medium ) {
				@include grid-column( 7 );
				@include grid-column-offset( 2 );
			}
		}
	}

	&.widget-calendar {
		.inner-grid {
			@include grid-row;
		}

		.event-wrap {
			@include grid-row;

			@include breakpoint( medium ) {
				@include grid-row-nest;
			}
		}

		.widget-title {
			@include grid-column( 12, 0 );
		}
		.event {
			@include grid-row;

			@include breakpoint( medium ) {
				@include grid-column( 4 );
			}
		}
	}

	&.owl_widget {
		overflow: hidden;
		.owl-carousel {
			@include grid-row;
		}
	}

	&.widget_mc4wp_form_widget {
		.widgettitle,
		.field,
		.description {
			@include grid-row;
		}

		@include breakpoint( medium ) {
			.widgettitle {
				@include grid-column( 2 );
			}

			.field {
				@include grid-column( 7 );
			}

			.description {
				@include grid-column( 3 );
			}
		}

		@include breakpoint( large ) {
			.field {
				@include grid-column( 6 );
			}

			.description {
				@include grid-column( 4 );
			}
		}
	}

	&.widget-teaser {
		.inner-grid {
			@include grid-row;
		}

		&.product {
			.menu-widgets & {
				@include grid-column( 4 );
			}
		}
	}

}
