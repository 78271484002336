.wc-list-categories {
	overflow: hidden;
	padding: 0 0 1rem;
	margin: 0 0 0.5rem;

	ul {
		overflow: hidden;
		margin: 0;
	}

	li {
		font-family: 'suisse_semibold';
		font-size: 130%;
		line-height: 1.25em;
		padding: 0;
		margin-bottom: 0.5em;

		@include breakpoint( large ) {
			float: left;
			margin-right: 1.5em;
		}
	}

}

.tax-product_cat,
.single-product {
	.wc-list-categories {
		border-bottom: 1px solid $silver;
		margin: 0 0 1.5rem;
	}
}