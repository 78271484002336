.article-content {
	.mesa-employees {
		margin-bottom: 2.5em;
		overflow: hidden;
	}

	.mesa-employee {
		margin-top: 0.9em;
		background: $white;

		.mesa-col {
			margin-bottom: 1.25rem;

			&:first-of-type {
				margin-bottom: 0;
			}

			&:only-of-type {
				margin-bottom: 1.25rem;
			}
		}

		h3 {
			margin: 0.75rem 1.25rem 0.5rem;
			font-family: 'suisse_semibold';
			font-size: 150%;
			line-height: 115%;
		}

		p {
			margin: 0.5rem 1.25rem 0.25rem;
			font-size: 90%;
		}

		.title {
			margin: 5px 0;
		}

		table {
			margin: 0;

			tbody {
				border: none;

				tr, tr:nth-child(even) {
					background: none;
				}
			}

			td {
				padding: 0 0 0.25em 1.25rem;
				background: none;
			}
		}

		img {
			width: 100%;
			margin: 0;
		}

		@include breakpoint( medium ) {
			width: 49%;
			float: left;

			&:nth-of-type(odd) {
				margin-right: 2%;
			}

			&:only-of-type {
				float: none;
				width: auto;
				margin-right: 0;
				overflow: hidden;

				.mesa-col {
					width: 50%;
					float: left;
				}
			}
		}
	}
}
