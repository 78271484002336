.shop-front-widgets {
	overflow: hidden;
	background: $white;
	box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
	border-radius: 0 0 4px 4px;

	> div {
		overflow: hidden;
		margin: 2rem 0;

		@include breakpoint( large ) {
			@include grid-column(4);
			text-align: center;
		}
	}

	> div.widget-teaser {
		margin: 0 ;

		@include breakpoint( medium ) {

			&:first-child {
				width: 66%;
				width: calc(66% - 1px);
				max-width: none;
				float: left;
			}

			&:not(:first-child) {
				width: 34%;
				height: 225px;
				float: right;

				.has-image {
					min-height: 225px;
				}
			}

			&:last-child {
				margin-top: 1px;
			}

		}

		h2 {
			display: none;
		}

		.has-image {
			min-height: 451px;
			position: relative;


			.featured {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				height: auto;
				z-index: 0;
				background-size: cover;
				border-radius: 0;

				&:after {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background: linear-gradient( 0deg, rgba( 0, 0, 0, 0.35 ), transparent );
				}
			}

			.content {
				position: absolute;
				bottom: 0;
				background: transparent;
				text-align: left;
				z-index: 1;
				color: #fff;
				max-width: 40em;

				.label {
					display: inline-block;
					margin-bottom: 0.75rem;
					color: $dark-navy;
					background: $white;
					text-transform: uppercase;
					font-weight: 700;
					padding: 0.33333rem 0.5rem 0.25rem;
				}

				h4 {
					font-size: 175%;
				}

				p {
					font-size: 100%;
					font-weight: 600;
				}

				.link a {
					display: inline-block;
					background: $primary-color;
					color: $white;
					line-height: 1;
					padding: .618em 1em;
					font-weight: 700;
					border-radius: 3px;

					&:hover, &:focus {
						background: darken( $primary-color, 5% );
					}
				}
			}
		}

		@include breakpoint( large ) {
			@include grid-column(12);
			@include grid-column-collapse;
			padding: 0 !important;
			text-align: center;
		}
	}

	h2 {
		font-family: 'suisse_semibold';
		font-size: 130%;
		padding: 0;
		color: $dark-navy;
	}

}