.widget_mc4wp_form_widget {
	background: $darkblue-gray;

	.inner-grid {
		padding: $global-margin 0;
	}

	@include breakpoint( medium down ) {
		.widgettitle,
		.description {
			text-align: center;
		}
	}

	.widgettitle {
		display: block;
		color: $gainsboro;
		font-family: 'suisse_semibold';
		text-transform: uppercase;
		font-size: 180%;
		padding: 0.75rem 0 0 !important;
	}

	.field {
		background: lighten( $darkblue-gray, 8% );
		padding: 0.75rem 0.5rem 0.75rem 1.5rem !important;
		border: rem-calc(1) solid darken( $gainsboro, 25% );
		@include border-top-radius( 2px );
		@include border-bottom-radius( 2px );

		label {
			display: none;
		}

		input[type="email"] {
			background: none;
			border: none;
			box-shadow: none;
			display: inline-block;
			width: 80%;
			margin: 0;
			padding: 0;
			color: $gainsboro;
		}

		input[type="submit"] {
			display: inline-block;
			width: 18%;
			background: darken( $darkblue-gray, 4% );
			border: rem-calc(1) solid darken( $gainsboro, 10% );
			@include border-top-radius( 2px );
			@include border-bottom-radius( 2px );
			text-transform: uppercase;
			font-size: 75%;
			font-weight: bold;
			color: $gainsboro;
			padding: 0.75rem 1.5rem;
		}

		@include breakpoint( medium down ) {
			input[type="email"] {
				width: 60%;
			}

			input[type="submit"] {
				width: 35%;
			}
		}
	}

	.description {
		padding-top: 0.75rem;
		color: darken( $white, 30% );
	}
}
