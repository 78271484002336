.sidebar {
	position: relative;

	.main & {
		margin-top: 0;
		padding-right: 0;

		.widget {
			margin-bottom: $global-padding;
			padding-right: 0;

			.inner-grid {
				@include breakpoint( large down ) {
					margin: 0;
				}
			}
		}
	}

	.work &,
	.event &,
	.artist & {
		h4 {
			font-family: 'suisse_semibold';
			font-size: 150%;
			line-height: 115%;
			text-transform: uppercase;
			margin-bottom: 1rem;
		}

		ul {
			margin: 0;

			li {
				margin-bottom: 1rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.data-label {
			display: block;
			font-style: italic;

			&::after {
				content: ' :';
				display: inline-block;
			}
		}
	}

	.event & {
		ul {
			background: $white;
			@include border-top-radius( 4px );
			@include border-bottom-radius( 4px );
			overflow: hidden;
			@include box-shadow( 0, 1px, 1px, 1px, rgba( 0, 0, 0, 0.04 ) );
			padding: 1.25rem;
		}

		li {
			font-weight: bold;

			.data-label {
				font-weight: normal;
				font-style: normal;
			}
		}
	}
}
