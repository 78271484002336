#exhibition-items {
	@include grid-row( $behavior: nest );

	.article {
		margin-bottom: 1.8rem;
		@include grid-column( 12 );

		@include breakpoint( medium ) {
			@include grid-column( 6 );
		}

		@include breakpoint( large ) {
			@include grid-column( 6 );
		}

		.article-header {
			.wp-post-image-wrap {
				.post-image {
					img {
						margin: 0;
						width: 100%;
						height: auto;
					}
				}
			}
		}

		.article-content {
			background: $white;
			@include border-bottom-radius( 4px );
			@include box-shadow( 0, 1px, 1px, 1px, rgba( 0, 0, 0, 0.04 ) );
			padding: 0.75rem 1.25rem 1.25rem;
			font-family: $body-font-family;
			position: relative;

			h2, h2 a {
				position: absolute;
				@include calc( 'width', '100% - 2 * 1.25rem' );
				top: - 2rem;
				font-family: 'harriet_bold';
				font-size: rem-calc( 35 );
				letter-spacing: -0.04em;
				line-height: 100%;
				text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
				color: $white;

				@include breakpoint( medium ) {
					font-size: 150%;
				}

				@include breakpoint( large ) {
					font-size: 175%;
				}

				.article-content-inner {
					display: flex;
					flex-direction: column;

					.article-meta {
						order: 1;
						margin-top: 0.5rem;
						margin-bottom: 0.75rem;
					}

					.article-excerpt {
						order: 2;
						margin: 0;
					}
				}
			}
		}

		.archive-footer {
			display: none;
		}

		.article-meta {
			font-weight: bold;
			a {
				color: $charcoal;
			}
		}
	}
}
