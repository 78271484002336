.single-post {
	.article {
		.article-header {
			@include grid-column( 10 );
			@include grid-column-offset( 2 );

			.article-title {
				@include breakpoint( medium ) {
					@include grid-column( 8, 0 );
				}
			}
		}

		.content-wrap {
			@include grid-column( 12 );
			@include breakpoint( medium ) {
				@include grid-column( 7 );
				@include grid-column-offset( 2 );
			}
		}

		#sidebar-posts {
			@include grid-column( 12 );
			@include breakpoint( medium ) {
				@include grid-column( 3 );
			}
		}
	}
}
