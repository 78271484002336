body.woocommerce, body.woocommerce-page {
	#respond input#submit.alt,
	a.button.alt,
	button.button.alt,
	input.button.alt {
		background-color: $primary-color;

		&:hover, &:focus {
			background-color: darken( $primary-color, 5% );
		}
	}
}

body.woocommerce #respond input#submit.alt.disabled, body.woocommerce #respond input#submit.alt.disabled:hover, body.woocommerce #respond input#submit.alt:disabled, body.woocommerce #respond input#submit.alt:disabled:hover, body.woocommerce #respond input#submit.alt:disabled[disabled], body.woocommerce #respond input#submit.alt:disabled[disabled]:hover, body.woocommerce a.button.alt.disabled, body.woocommerce a.button.alt.disabled:hover, body.woocommerce a.button.alt:disabled, body.woocommerce a.button.alt:disabled:hover, body.woocommerce a.button.alt:disabled[disabled], body.woocommerce a.button.alt:disabled[disabled]:hover, body.woocommerce button.button.alt.disabled, body.woocommerce button.button.alt.disabled:hover, body.woocommerce button.button.alt:disabled, body.woocommerce button.button.alt:disabled:hover, body.woocommerce button.button.alt:disabled[disabled], body.woocommerce button.button.alt:disabled[disabled]:hover, body.woocommerce input.button.alt.disabled, body.woocommerce input.button.alt.disabled:hover, body.woocommerce input.button.alt:disabled, body.woocommerce input.button.alt:disabled:hover, body.woocommerce input.button.alt:disabled[disabled], body.woocommerce input.button.alt:disabled[disabled]:hover {
	background-color: $ocher;
}

.woocommerce-variation-price {
	margin-bottom: 10px;
}