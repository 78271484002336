@import '../fonts/fontawesome/css/font-awesome.min.css';
// @import url(https://fonts.googleapis.com/css?family=Playfair+Display:900);
// @import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:700);

@font-face {
    font-family: 'harriet_bold';
    src: url( '../fonts/harriet/harrietdisplay-bold-1v6-webfont.eot' );
    src: url( '../fonts/harriet/harrietdisplay-bold-1v6-webfont.eot?#iefix' ) format( 'embedded-opentype' ),
         url( '../fonts/harriet/harrietdisplay-bold-1v6-webfont.woff2' ) format( 'woff2' ),
         url( '../fonts/harriet/harrietdisplay-bold-1v6-webfont.woff' ) format( 'woff' ),
         url( '../fonts/harriet/harrietdisplay-bold-1v6-webfont.ttf' ) format( 'truetype' ),
         url( '../fonts/harriet/harrietdisplay-bold-1v6-webfont.svg#harriet_bold' ) format( 'svg' );
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'suisse';
    src: url( '../fonts/suisse/SuisseIntlCond-Regular-WebXL.eot' );
    src: url( '../fonts/suisse/SuisseIntlCond-Regular-WebXL.eot?#iefix' ) format( 'embedded-opentype' ),
         url( '../fonts/suisse/SuisseIntlCond-Regular-WebXL.woff2' ) format( 'woff2' ),
         url( '../fonts/suisse/SuisseIntlCond-Regular-WebXL.woff' ) format( 'woff' ),
         url( '../fonts/suisse/SuisseIntlCond-Regular-WebXL.ttf' ) format( 'truetype' ),
         url( '../fonts/suisse/SuisseIntlCond-Regular-WebXL.svg#suisse' ) format( 'svg' );
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'suisse_semibold';
    src: url( '../fonts/suisse/suisseintlcond-semibold-webfont.eot' );
    src: url( '../fonts/suisse/suisseintlcond-semibold-webfont.eot?#iefix' ) format( 'embedded-opentype' ),
         url( '../fonts/suisse/suisseintlcond-semibold-webfont.woff2' ) format( 'woff2' ),
         url( '../fonts/suisse/suisseintlcond-semibold-webfont.woff' ) format( 'woff' ),
         url( '../fonts/suisse/suisseintlcond-semibold-webfont.ttf' ) format( 'truetype' ),
         url( '../fonts/suisse/suisseintlcond-semibold-webfont.svg#suisse' ) format( 'svg' );
    font-weight: normal;
    font-style: normal;
}

main .article-content {

	p {
		margin: 0.25em 0 1em;
	}

	ul, ol {
		padding-left: 2em;
	}

	> ul, > ol {
		margin: 1.5em 0;
	}

	ul {
		list-style-type: disc;
	}

	> ul > li > ul {
		list-style-type: circle;
	}

	ol {
		list-style-type: decimal;
	}

	> ol > li > ol {
		list-style-type: lower-latin;
	}

	li {
		margin: 0.5em 0;
	}

	ul > li {
		list-style-type: disc;
	}

	ol > li {
		list-style-type: decimal;
	}

	dl {
		margin: 1.5em 0;
	}

	dt {
		margin-top: 0.5em;
		font-weight: 700;
	}
}
