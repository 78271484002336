figure {
	margin: 0;
}

// Gallery shortcode
.gallery {
	@include grid-row();

	.gallery-item {
		@include grid-column( 12 );
		margin-bottom: $grid-column-gutter;
	}

	@include breakpoint(large) {
		&.gallery-columns-3 {

			.gallery-item {
				$grid-column-gutter-tmp: $grid-column-gutter;
				$grid-column-gutter: 5px;

				@include grid-column( 4 );
				min-height: 150px;

				$grid-column-gutter: $grid-column-gutter-tmp;

				&:nth-child( 3n+3 ) {
					margin-right: 0;

					&:after {
						@include clear();
					}

					@include clearfix()
				}
			}
		}
	}
}
