body {
	overflow-x: hidden;
}

iframe {
	max-width: 100%;
}

ul {
	margin: 0;

	li {
		list-style-type: none;
	}
}

a {
	text-decoration: none;
}

input {
	border: none;
}

:focus {
	outline-style: none;
	box-shadow: none;
	border-color: transparent;
}
