.woocommerce.post-type-archive-product,
.woocommerce.tax-product_cat {

	#container, #content, .archive-content {
		&:before,
		&:after {
			content: " ";
			display: table;
		}
		&:after {
			clear: both;
		}
	}

	.owl-carousel-plugin {
		box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
	}

	.h3 {
		font-family: 'suisse_semibold';
		font-size: 120%;
		color: $dark-navy;
	}

	h1.page-title {
		font-family: 'suisse_semibold';
		font-size: 225%;
		color: $dark-navy;

		@include breakpoint( medium ) {
			float: left;
		}
	}

	.woocommerce-result-count {
		display: none;
	}

	.woocommerce-ordering {
		float: none;

		@include breakpoint( medium ) {
			float: right;
		}

		select {
			border: 1px solid #aaa;
		}
	}



}