.widget_nav_menu {
	.menu {
		@include menu-base;
		@include menu-direction( vertical );

		.sub-menu {
			display: none;
		}
	}
}
