body.woocommerce ul.products {
	margin-bottom: -1.5em;
}

.woocommerce #content ul.products li.product .woocommerce-loop-product__title {
	font-family: 'suisse_semibold';
	font-size: 120%;
	color: $dark-navy;
	padding-left: 1rem;
	padding-right: 1rem;
}

.woocommerce .products .added_to_cart {
	position: absolute;
    bottom: 11px;
    right: 15px;
}

.woocommerce-mini-cart__buttons .checkout {
	margin-left: 5px !important;
}

.woocommerce ul.products li.product,
.woocommerce-page ul.products li.product {
	background: $white;
	box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
	margin-bottom: 1.5em !important;
	border-radius: 4px;

	img {
		border-radius: 4px 4px 0 0;
	}

	a {
		> *:not(img) {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		&:hover img {
			opacity: 0.9;
		}

		h3 {
			font-family: 'suisse_semibold';
			font-size: 120%;
			color: $dark-navy;
		}

		.price {
			color: $dark-navy;
			ins {

				font-weight: normal;
				text-decoration: none;
			}
		}
	}

	.button {
		margin: 1rem;
		color: $white;
		background-color: $primary-color;

		&:hover, &:focus {
			background-color: darken( $primary-color, 5% );
		}
	}

}

body.woocommerce ul.products li.product,
body.woocommerce-page ul.products li.product {
	width: 23.08%;
	margin-right: 2.5%;
}

body.woocommerce ul.products li.product.last,
body.woocommerce-page ul.products li.product.last {
	margin-right: 0;
}


@media (max-width: 768px) {

	body.woocommerce ul.products li.product,
	body.woocommerce-page ul.products li.product,
	body.woocommerce ul.products li.product:nth-child(2n),
	body.woocommerce-page ul.products li.product:nth-child(2n),
	body.woocommerce-page[class*=columns-] ul.products li.product:nth-child(2n),
	body.woocommerce[class*=columns-] ul.products li.product:nth-child(2n) {
		margin-right: 0 !important;
		width: 48%;
	}
}

@media (max-width: 400px) {

	body.woocommerce ul.products li.product,
	body.woocommerce-page ul.products li.product {
		width: 100% !important;
		float: none !important;
	}

	body.woocommerce ul.products li.product:nth-child(2n),
	body.woocommerce-page ul.products li.product:nth-child(2n),
	body.woocommerce-page[class*=columns-] ul.products li.product:nth-child(2n),
	body.woocommerce[class*=columns-] ul.products li.product:nth-child(2n) {
		float: none;
	}
}

body.woocommerce nav.woocommerce-pagination {
	clear: both;
	margin: 1em 0 0;

	ul li {
		span, a {
			background: $white;
			padding: 0.75em 0.95em;
		}

		a {
			&:hover, &:focus {
				background: $primary-color;
				color: $white;
			}
		}

		span.page-numbers.current {
			background: $white;
		}
	}
}

.woocommerce ul.products li.product .woocommerce-LoopProduct-link span.onsale, .woocommerce.single-product span.onsale {
	border-radius: 0;
	padding: 12px 15px;
	margin: 0;
	line-height: 1;
	min-height: auto;
	z-index: 1;
	background-color: $blue-gray;
	border-radius: 3px;
}

.woocommerce.single-product span.onsale {
	top: 0;
	left: 0;
}

.woocommerce-LoopProduct-link .price .amount {
	&:first-child {
		margin-right: 5px;
	}
	&:last-child {
		margin-left: 5px;
	}
}

.woocommerce-Price-amount {
	color: #394448;
}

.woocommerce.single-product div.product p.price, .woocommerce.single-product div.product span.price {
	color: #394448;
}

.woocommerce.single-product div.product p.price ins {
	text-decoration: none;
}
