.single-product {
	.pp_woocommerce {
		.pp_expand {
			display: none !important;
		}

		.pp_next {
			@include icons( '\f105', 'fontawesome', 'before', $white, '0' );

			&::before {
				background-color: transparent;
				font-size: 4rem !important;
				right: 0;
			}
		}
		.pp_previous {
			@include icons( '\f104', 'fontawesome', 'before', $white, '0' );

			&::before {
				background-color: transparent;
				font-size: 4rem !important;
				left: 0;
			}
		}

		.pp_details {
			.pp_arrow_previous {
				@include icons( '\f104', 'fontawesome', 'before', #444, '0' );
				background-color: transparent;
				margin-top: 2px;
			}
			.pp_arrow_next {
				@include icons( '\f105', 'fontawesome', 'before', #444, '0' );
				background-color: transparent;
				margin-top: 2px;
			}
		}
	}

	div.product form.cart .variations label {
		font-weight: 700;
		padding: 10px 0px 10px 15px;
	}
	div.product form.cart .variations .label {
		background-color: transparent;
	}
	div.product form.cart .variations select {
		margin-bottom: 0;
	}
	div[itemprop="description"] {
		background-color: #fff;
		padding: 15px 15px 0px 15px;
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.reset_variations {
		display: none;
	}
	.price {
		.amount {
			&:first-child {
				margin-right: 10px;
			}
			&:last-child {
				margin-left: 10px;
			}
		}
	}
	.description {
		h2 {
			display: none;
		}
	}
}

body.woocommerce ul.products {
	display: flex;
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button

body.woocommerce.single-product h1.product_title {
	padding-top: 3px;
}

body.woocommerce ul.products li.product, body.woocommerce-page ul.products li.product {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: space-between;
	-ms-justify-content: space-between;
	justify-content: space-between;
	position: relative;
}

.woocommerce ul.products li.product .button, .woocommerce-page ul.products li.product .button,
.woocommerce ul.products li.product .button, .woocommerce-page ul.products li.product input.button {
	-webkit-align-self: baseline;
	-ms-align-self: baseline;
	align-self: baseline;

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		position: absolute;
		bottom: 0;
		left: auto;
	}
}

ul.products {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	li.product {
		height: 440px;
	}
}

li.product {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

body.woocommerce table.shop_attributes {
	tbody tr {
		&:first-child {
			display: none;
		}
		&:nth-child(even) {
			background-color: #fff;
		}
	}
}
