.widget_recent_entries {
	.widgettitle {
		display: block;
		font-family: 'suisse_semibold';
		font-size: 150%;
		color: $dark-navy;
		line-height: 115%;
		text-transform: uppercase;
	}

	a,
	.post-date {
		display: block;
	}

	ul {
		margin-left: 0;
	}

	li {
		margin-top: 1rem;
	}

	.post-date {
		font-size: 90%;
	}
}
