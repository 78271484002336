body.woocommerce-page.woocommerce-cart {

	h2 {
		font-family: 'suisse_semibold';
		font-size: 180%;
		padding: 0 0 0.4em;
		color: $dark-navy;
	}

}

body.woocommerce-cart {
	main .article-content p {
		margin: 0 0 1em;
	}
	td.product-name dl.variation dd {
		margin-bottom: 0;
	}
}