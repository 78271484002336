.pagination-wrap {
	text-align: right;
	font-weight: bold;

	> div {
		display: inline-block;
	}

	.previous-page {
		@include icons( '\f104', 'fontawesome', 'before', $ocher );
	}

	.next-page {
		margin-left: 1.5rem;
		@include icons( '\f105', 'fontawesome', 'after', $ocher);
	}
}
