.page:not(.home),
.error404,
.single-artist,
.single-event,
.single-exhibition,
.single-museum,
.single-work {
	.article {
		.article-title {
			@include grid-column-offset( 1 );
			@include breakpoint( medium ) {
				@include grid-column-offset( 2 );
			}
			.title-wrap {
				@include grid-row;
			}

			.subtitle {
			}
		}

		.breadcrumb-wrap,
		.content-wrap {
			@include grid-row;

			@include breakpoint( medium ) {
				@include grid-column( 7 );
				@include grid-column-offset( 2 );
			}
		}

		.sidebar {
			@include grid-row;

			@include breakpoint( medium ) {
				@include grid-column( 3 );
			}
		}
	}

	.gallery-wrap {
		.owl-carousel {
			@include grid-row;
		}
	}

	.related {
		.inner-grid {
			> .work,
			> .artist {
				@include grid-column( 12 );

				@include breakpoint( medium ) {
					@include grid-column( 3 );
				}
			}
		}
	}

	.fullwidth {
		.breadcrumb-wrap,
		.content-wrap {
			@include grid-row;

			@include breakpoint( medium ) {
				@include grid-column( 12 );
				@include grid-column-offset( 0 );
			}
		}
	}
}

// Change column orders on works
.single-work .article,  .single-artist .article {

	.breadcrumb-wrap {
		@include breakpoint( medium ) {
			@include grid-column( 10 );
		}
	}

	.sidebar {
		@include breakpoint( medium ) {
			@include grid-column-position(9);
		}
	}

	.content-wrap {
		@include breakpoint( medium ) {
			@include grid-column-position(-3);
		}
	}
}
