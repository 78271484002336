.filters {

	@include breakpoint( medium ) {
		padding: 0.9em;
	}

	ul, li {
		display: inline-block;
	}

	ul {
		margin: 0;
	}

	.museums {
		li {
			margin-right: 1rem;
		}

		input[type="checkbox"] {
			display: none;
			position: relative;

			+ label span {
				display: inline-block;
				background: $white;
				border: rem-calc(1) solid $silver;
				width: rem-calc(25);
				height: rem-calc(25);
				overflow: hidden;
				@include border-top-radius( 4px );
				@include border-bottom-radius( 4px );
				vertical-align: middle;
				margin: 0;
				margin-right: rem-calc(8);
				position: relative;

				&::after {
					content: '';
				}

				&:hover {
					cursor: pointer;
				}
			}

			&:checked + label span::after {
				position: absolute;
				left: rem-calc(3);
				bottom: rem-calc(10);
				width: rem-calc(24);
				height: rem-calc(14);
				border-bottom: rem-calc(4) solid $ocher;
				border-left: rem-calc(4) solid $ocher;
				transform: rotate(-45deg);
			}
		}

		label {
			display: inline-block;
			margin-left: 0;
			vertical-align: middle;
			font-family: 'suisse_semibold';
			font-size: 115%;
		}
	}

	.time {
		margin-top: 1em;

		li {
			margin-bottom: 0.66em;
		}

		input[type="checkbox"] {
			display: none;
			position: relative;

			+ label {
				display: inline-block;
				background: $white;
				border: rem-calc(1) solid $silver;
				width: rem-calc(55);
				height: rem-calc(30);
				overflow: hidden;
				@include border-top-radius( 4px );
				@include border-bottom-radius( 4px );
				vertical-align: middle;
				margin: 0;
				margin-right: rem-calc(10);
				font-weight: bold;
				text-transform: uppercase;
				text-align: center;

				&:hover {
					cursor: pointer;
				}
			}

			&:checked + label {
				background: $ocher;
				color: $white;
			}
		}
	}
}
