.wp-post-image-wrap {
	body.woocommerce-page #content > .fullwidth > & {
		@include calc( 'height', '100% - #{rem-calc(500)}' );
		@include calc( 'height', '100vh - #{rem-calc(500)}' );

		@include breakpoint( medium ) {
			@include calc( 'height', '100% - #{rem-calc(90)}' );
			@include calc( 'height', '100vh - #{rem-calc(90)}' );
		}

		.post-image {
			position: fixed;
			height: 100%;
			@include background-cover;
			background-position: top center;
			width: 100%;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				background: linear-gradient( 77deg, rgba( 0, 0, 0, 0.5 ), transparent );
			}
		}

	}

	#content > div > & {
		@include calc( 'height', '100% - #{rem-calc(280)}' );
		@include calc( 'height', '100vh - #{rem-calc(280)}' );
		min-height: 320px;

		@include breakpoint( medium ) {
			@include calc( 'height', '100% - #{rem-calc(90)}' );
			@include calc( 'height', '100vh - #{rem-calc(90)}' );
			min-height: 42em;
		}

		.post-image {
			position: fixed;
			height: 100%;
			@include background-cover;
			background-position: top center;
			width: 100%;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				background: linear-gradient( 77deg, rgba( 0, 0, 0, 0.5 ), transparent );
			}
		}

	}

	.single-post & {
		display: none;
	}
}
